var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.orgInvitations.length && !_vm.fetchingOrgInvitations
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-center" },
              [
                _vm._l(_vm.availableInvitationTypes, function (invitationType) {
                  return _c(
                    "div",
                    { key: invitationType },
                    [
                      _c("v-checkbox", {
                        staticClass: "mx-4",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            small: "",
                                            color:
                                              _vm.getMailIconColor(
                                                invitationType
                                              ),
                                          },
                                        },
                                        [_vm._v("mail")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "secondary--text caption text-uppercase",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .invitationAndRequestStatusTypes[
                                                invitationType
                                              ]
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.invitationsSwitchData[invitationType],
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.invitationsSwitchData,
                              invitationType,
                              $$v
                            )
                          },
                          expression: "invitationsSwitchData[invitationType]",
                        },
                      }),
                    ],
                    1
                  )
                }),
                _c("v-spacer"),
                _c("v-text-field", {
                  attrs: {
                    autofocus: "",
                    "append-icon": "search",
                    placeholder: "Filter",
                    outlined: "",
                    dense: "",
                    "hide-details": "",
                  },
                  model: {
                    value: _vm.invitationEmailSearch,
                    callback: function ($$v) {
                      _vm.invitationEmailSearch = $$v
                    },
                    expression: "invitationEmailSearch",
                  },
                }),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-4",
                                    attrs: { text: "", icon: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$store.dispatch(
                                          "orgStore/fetchOrgInvitations",
                                          _vm.$route.params.oid
                                        )
                                      },
                                    },
                                  },
                                  on
                                ),
                                [_c("v-icon", [_vm._v("refresh")])],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1572898915
                    ),
                  },
                  [_c("span", [_vm._v("refresh")])]
                ),
              ],
              2
            ),
            _c("v-divider", { staticClass: "mb-5" }),
            _c("v-data-iterator", {
              attrs: { items: _vm.filteredInvitationsList },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._l(props.items, function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              index > 0
                                ? _c("v-divider", {
                                    staticClass: "mb-2",
                                    staticStyle: {
                                      opacity: "0.25",
                                      "border-width": "thin",
                                    },
                                    attrs: { inset: "" },
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "space-between",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: _vm.getMailIconColor(
                                                  _vm.getInvitationStatus(item)
                                                ),
                                                "x-large": "",
                                              },
                                            },
                                            [_vm._v("mail")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "5" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.requestee_email
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column",
                                                  },
                                                  [
                                                    item.notification_timestamp
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "caption mt-1",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Sent on:"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item.notification_timestamp
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.getInvitationStatus(
                                                      item
                                                    ) ===
                                                      _vm
                                                        .invitationAndRequestStatusTypes
                                                        .REVOKED &&
                                                    item.revocation_timestamp
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "caption mt-1 mb-0",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Revoked on:"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "dateTimeToHuman"
                                                                  )(
                                                                    item.revocation_timestamp
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm.getInvitationStatus(
                                                      item
                                                    ) ===
                                                      _vm
                                                        .invitationAndRequestStatusTypes
                                                        .ACCEPTED &&
                                                    _vm.invitationOrRequestHasTimestamp(
                                                      item
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mt-1 caption",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Accepted on:"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "dateTimeToHuman"
                                                                    )(
                                                                      item
                                                                        .invitation_responses[0]
                                                                        .timestamp
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm.getInvitationStatus(
                                                          item
                                                        ) ===
                                                          _vm
                                                            .invitationAndRequestStatusTypes
                                                            .REJECTED &&
                                                        _vm.invitationOrRequestHasTimestamp(
                                                          item
                                                        )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mt-1 caption",
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "font-weight-bold",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Rejected on:"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "dateTimeToHuman"
                                                                    )(
                                                                      item
                                                                        .invitation_responses[0]
                                                                        .timestamp
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-subtitle",
                                                _vm._l(
                                                  item.roles,
                                                  function (role, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "d-flex flex-column",
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: { left: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      role.role_type ===
                                                                      _vm
                                                                        .invitationAndRequestRoleTypes
                                                                        .ORG_ROLE
                                                                        ? _c(
                                                                            "div",
                                                                            _vm._g(
                                                                              {},
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "ComponentWithStyledBorder",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex align-center pa-1",
                                                                                    },
                                                                                    [
                                                                                      role.Name ===
                                                                                      _vm
                                                                                        .roleTypes
                                                                                        .ORG_MANAGER
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "corporate_fare"
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : role.Name ===
                                                                                          _vm
                                                                                            .roleTypes
                                                                                            .ORG_FACULTY
                                                                                        ? _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "supervisor_account"
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "person"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-1",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              role.Name
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            role.Name === "N/A"
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "The target object has been deleted"
                                                                  ),
                                                                ])
                                                              : role.method ===
                                                                _vm
                                                                  .roleUpdateOptions
                                                                  .OVERWRITE_CURRENT_ROLE
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " This role will overwrite the current user role, even if the current role is more powerful that this one. "
                                                                  ),
                                                                ])
                                                              : role.method ===
                                                                _vm
                                                                  .roleUpdateOptions
                                                                  .OVERWRITE_CURRENT_ROLE_IF_LOWER
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " This role will overwrite the current role only if the current role is less powerful than this one. "
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.isOrgManager
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "1" } },
                                            [
                                              _vm.getInvitationStatus(item) ===
                                                _vm
                                                  .invitationAndRequestStatusTypes
                                                  .PENDING ||
                                              _vm.getInvitationStatus(item) ===
                                                _vm
                                                  .invitationAndRequestStatusTypes
                                                  .CREATED
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "InvitationActionsDialog",
                                                            {
                                                              attrs: {
                                                                successFetchAction:
                                                                  () => {
                                                                    _vm.$store.dispatch(
                                                                      "orgStore/fetchOrgInvitations",
                                                                      _vm.$route
                                                                        .params
                                                                        .oid
                                                                    )
                                                                  },
                                                                invitationInfo:
                                                                  item,
                                                                actionType:
                                                                  _vm
                                                                    .actionTypes
                                                                    .RESEND_INVITATION,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "div",
                                                                            _vm._g(
                                                                              {},
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "copy-to-clipboard",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      textToCopy:
                                                                                        item.link,
                                                                                      buttonClass:
                                                                                        "caption font-weight-bold",
                                                                                      buttonColor:
                                                                                        "secondary",
                                                                                      buttonName:
                                                                                        "copy to clipboard",
                                                                                      iconButton: true,
                                                                                      isSmall: true,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Copy invitation link"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "InvitationActionsDialog",
                                                            {
                                                              attrs: {
                                                                successFetchAction:
                                                                  () => {
                                                                    _vm.$store.dispatch(
                                                                      "orgStore/fetchOrgInvitations",
                                                                      _vm.$route
                                                                        .params
                                                                        .oid
                                                                    )
                                                                  },
                                                                invitationInfo:
                                                                  item,
                                                                actionType:
                                                                  _vm
                                                                    .actionTypes
                                                                    .REVOKE_INVITATION,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _vm.isOrgManager
                          ? _c(
                              "div",
                              [
                                _c("invite-btn", {
                                  on: {
                                    goToInvite: function ($event) {
                                      return _vm.$emit("goToInvite", {
                                        value: true,
                                      })
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "no-results",
                    fn: function () {
                      return [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mt-3",
                            attrs: {
                              value: true,
                              color: "error",
                              icon: "warning",
                              text: "",
                            },
                          },
                          [_vm._v("Your search for found no results.")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3830285877
              ),
            }),
          ],
          1
        )
      : !_vm.orgInvitations.length && !_vm.fetchingOrgInvitations
      ? _c(
          "div",
          [
            _c(
              "v-banner",
              { attrs: { "single-line": "" } },
              [
                _c(
                  "v-avatar",
                  {
                    attrs: { slot: "icon", color: "white", size: "60" },
                    slot: "icon",
                  },
                  [
                    _c(
                      "v-icon",
                      { attrs: { "x-large": "", icon: "info", color: "info" } },
                      [_vm._v("info")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c(
                    "span",
                    { staticClass: "font-weight-bold secondary--text" },
                    [_vm._v("No invitations found")]
                  ),
                  _c("p", [
                    _vm._v(
                      " Currently this organization has no invitation history. Consider inviting new users via the "
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-uppercase font-weight-bold secondary--text",
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { small: "", color: "primary" } },
                          [_vm._v("add")]
                        ),
                        _vm._v(" invite "),
                      ],
                      1
                    ),
                    _vm._v(" tab above. "),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _vm.fetchingOrgInvitations
      ? _c(
          "div",
          _vm._l(
            [
              { id: "c1", opacity: 1 },
              { id: "c2", opacity: 0.75 },
              { id: "c3", opacity: 0.5 },
            ],
            function (item) {
              return _c("div", { key: item.id, staticClass: "my-6" }, [
                _c(
                  "div",
                  { style: { opacity: item.opacity } },
                  [
                    _c("v-skeleton-loader", {
                      staticClass: "mx-auto",
                      attrs: { type: "list-item-avatar-two-line" },
                    }),
                  ],
                  1
                ),
              ])
            }
          ),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }